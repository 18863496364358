import styled from 'styled-components';

export const body = styled.div`
display: flex ;
height:100vh ;
width: 100vw;
min-width: 300px ;
margin: 0 ;
padding: 0 ;
font-size:14px;

background-color: black;
flex-direction: column ;
align-items: center ;


textarea, select, input{
  color: white ;
}
button{
  cursor: pointer;
}


select{
  background-color:#5656f7;
  color: white;
  border-radius: 3px ;
}

a{
  text-decoration:none;
  color: white;
  outline:0;
  background:none;
  border:0;
  cursor: pointer;

  &:hover{
    color:#00ff9d;
    }
}

`


// --------------------------------------------------------
export const bodyMeio = styled.div` 
display:flex;
flex-direction: column ;
width: 100% ;
background-color: black ;
align-items: center ;

button{
  width: 100px ;
}
`
//---------------------------------------------
export const imagem = styled.div`
display: flex ;
width: 100% ;
align-items: flex-start;
justify-content: center ;


img{
  width: 50% ;
  min-width: 300px ;
}

`

// --------------------------------------------------------
export const Footer = styled.div`
display: flex ;
align-items: center ;
justify-content: center ;
position:absolute ;
bottom:0px ;
width:100%;
height:25px ;
border-top:1px solid #5656f7;
font-size: 11px ;
color: white ;
div{
  display: flex ;
  margin-right: 10px ;
  align-items: center ;
  color: white ;
  padding: 10px 0 ;
}

`
