import React, { createContext, useReducer} from 'react';

//-----------------------------------------------------

const inicialState = {
}

//-----------------------------------------------------
export const Context = createContext({
    state: inicialState,
    dispatch: () => {}
});
//-----------------------------------------------------

const principalReducer = (state, action) => ({

});
//-----------------------------------------------------
export const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(principalReducer, inicialState);

    return(
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}
