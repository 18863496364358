import React, { useEffect, useState, useRef } from 'react';
import {Link} from 'react-router-dom';

import * as S from './styles';
import autoboox_img from '../../midia/autoboox/AutoBox3D1.png'
import {HeaderLogo} from '../../componentes/Header/HeaderLogo';

//----------------------------------------------------
export default function Site_autoboox() {  
    //camera frontal ou traseira
    const [front, setfront] = useState(false);
    const videosRef = useRef(null);
    const capturaRef = useRef(null);
    const [baixarURL, setbaixarURL] = useState(null)
//--------------------------------------------
    async function ViedeoCamera(){
        await navigator.mediaDevices.getUserMedia(
            {
                audio: false,
                video:{
                    width:3060, 
                    height:4080, 
                    // aspectRatio: 12/21,
                    facingMode: front ? 'user' : 'environment' 
                }
            })
        .then(stream=>{
            let video = videosRef.current;
            video.srcObject = stream;
            video.play();
            
        }).catch(erro=>{
            alert("Não foi possível acessar a câmera");
        })
    };
    
    //---------------------------------------
    function Capturar(){
        const canvas = capturaRef.current;
        const video = videosRef.current;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0);
        setbaixarURL(canvas.toDataURL('image/jpeg'));
    };
    //---------------------------------------
    useEffect(() => {
        ViedeoCamera()
    }, [videosRef]);

//#################################################################################################
  return (
    <S.body>
        <S.Container>
            <div className='desabilitar'/>
            
{/* //---------------------------------------------------------    */}       
            <HeaderLogo/>

    {/* //--------------------------------------------------------- */} 
            <S.bodyMeio>
                <S.imagem >
                    <img src={autoboox_img} />
                </S.imagem>
                <button onClick={()=>setfront(true)}>mudar</button> 

                <span>"----"</span>

                <button onClick={()=>Capturar()}>capturar</button>

                <span>"----"</span>

                <a href={baixarURL} role="button" download="nomeImg">Baixar</a>

                <Link to='/webcam'>webCam </Link>

                
                <video 
                style={{'display':"flex", 'maxWidth':'400px', 'width':"100vw"}}
                ref={videosRef} 
                autoPlay 
                />

                <canvas 
                style={{'display':"flex", 'maxWidth':'400px', 'width':"100vw"}}
                ref={capturaRef}
                width="3060px"
                height="4080px"
                />
                
            </S.bodyMeio>
                
            <S.Footer>
                    <span>Desenvolvido por - </span> 
                    <span 
                    style={{'color':"#00ff9d",'cursor':'pointer'}}
                    onClick={()=> window.location.href = 'https://wa.me/qr/J6BX3LNBZACLH1'} 
                    >
                        RBento 
                    </span> 
            </S.Footer>
{/* //---------------------------------------------------------    */}
        </S.Container>
    </S.body>
  );
}