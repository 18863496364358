
import React, {useContext } from 'react';
import * as S from './styles';
import logo from '../../midia/autoboox/cadeado3D.png';
import {Link} from 'react-router-dom';

export function HeaderLogo(){

// ###########################################################################
    return(
        <S.HeaderLogo>
            { false &&
            <div className='voltar'>
                <Link to={'/'}>Voltar</Link>
            </div>
            }
            <div className='logoCentro'>
            </div>
        </S.HeaderLogo>
    )
};

