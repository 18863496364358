import styled from 'styled-components';

// --------------------------------------------------------
export const HeaderLogo = styled.div`
display: flex ;
align-items: center ;
justify-content: center ;
width:100%;
border-bottom:1px solid #5656f7;
min-height: 40px ;

img{
  width: 8em ;
}

.logoCentro{
  display: flex ;
  position: absolute ;
}

.voltar{
  display: flex ;
  flex: 1;
  font-size: 15px ;
  font-weight: bolder ;
  margin-left: 30px;
}

`
// #################################################
export const Header = styled.div`
display:flex;
width:100%;
min-width: 500px ;
position:fixed;
top:0 ;
z-index:1;
border-bottom: 10px solid #3881ff;
justify-content:end;

`
export const Header1 = styled.div`
height:40px;
display:flex;
flex:1;
align-items:center;
justify-content:flex-start;
background-color:#262626;
font-size:14px;
color: #F2F2F2;

`
export const Header2 = styled.div`
height:40px;
display:flex;
width:50% ;
min-width: 380px ;
align-items:center;
justify-content:space-evenly;
background-color:#262626;
font-size:14px;
color: #F2F2F2;


a, button{
  text-decoration:none;
  color: white;
  outline:0;
  background:none;
  border:0;
  cursor: pointer;

  &:hover{
    color:#00D594;
    }
}
button{
    font-size:14px;
}

img{
        width:30px;
        height:30px;
        &:hover{
        opacity: 0.5;
        }
    }
//-----------
#notification{
    background:none;
    border:none;
    cursor:pointer;

    
    span{
        background:#fff;
        color:#6D39FF;
        font-weight:bold;
        font-size:14px;
        padding:1px 7px;
        border-radius:50%;
        position:relative;
        top:-15px;
        right:10px;
    }
}
//-----------
div{
    display:flex;

    img{
        width:30px;
        height:30px;
        cursor:pointer;

        &:hover{
        opacity: 0.5;
        }
    }
    }
//-----------
    .divisao::after{
        content:'|';
        margin: 0 10px;
        color: #fff;
    }
`
//-----------
export const Icone = styled.div`
display:flex;
align-items:center;
margin-left:80px;

img{
width:35px;
height:35px;
}
`
//-----------
export const ss = styled.div`
display:flex;

span{
        display:flex;
        align-items:center;
        color:#ffbf00;
        margin-left:5px;
        }
`

export const iconConta = styled.div`
    flex-direction:column;
    position: absolute;
    top:50px;
    right:8%;

    span{
        color:black;
        }

#conta{
    display:flex;
    background-color:white;
    width:100px;
    height:20px;
    border-radius:2px;
    justify-content:center;

    &:hover{
        opacity: 0.5;
        }
}

#sair{
    display:flex;
    background-color:white;
    width:100px;
    height:20px;
    border-radius:2px;
    justify-content:center;
    margin-top:3px;

    &:hover{
        opacity: 0.5;
        }
}
`
//-----------
