import styled from 'styled-components';

export const body = styled.div`
display: flex ;
height:100vh ;
width: 100vw;
min-width: 370px ;
zoom: 1.0;
min-zoom: 1.0;
max-zoom: 2.0;
margin: 0 ;
padding: 0 ;
font-size:18px;
justify-content: center ;
textarea, select, input{
  color: white ;
}
button{
  cursor: pointer;
}

`
//--------------------------------------------
export const Container = styled.div`
display:flex;
height:100vh ;
width: 100vw;
min-width: 370px ;
zoom: 1.0;
min-zoom: 1.0;
max-zoom: 2.0;
background-color: black;
flex-direction: column ;
align-items: center ;

select{
  background-color:#5656f7;
  color: white;
  border-radius: 3px ;
}

a{
  text-decoration:none;
  color: white;
  outline:0;
  background:none;
  border:0;
  cursor: pointer;

  &:hover{
    color:#00ff9d;
    }
}

`

//------------------Modal--------------------------------------
export const fundoModal = styled.div`
display: ${props=>props.modalFundo?'flex':'none'} ;
width: 100%;
height:100% ;
font-size:18px;
background-color: black ;
position: fixed ;
opacity: 0.7 ;

`

export const modalInforme = styled.div`
  display: ${props=>props.modalInforme?'flex':'none'} ;
  width: 100vw ;
  max-width:400px ;
  padding: 20px 0px ;
  position: absolute;
  top:300px;
  border-radius: 5px ;
  background-color: black ;
  z-index: 1 ;
  color: white ;
  font-size: 14px ;

`
export const bodyModal = styled.div`
display: flex ;
margin-top: 5px ;
font-size:13px;
min-width: 375px ;

`
export const dados = styled.div`
  display: flex ;
  flex: 1 ;
  min-width:375px ;
  padding: 7px 0 ;
  font-size: 14px ;
  text-transform:capitalize;



`
export const sair = styled.div`
  display: ${props=>props.logSair ? 'flex':'none'} ;
  background-color: red ;
  padding: 0 8px ;
  font-size: 12px ;
  color: white ;
  border-radius: 1px ;
  align-items: center ;
  justify-content: center ;
  cursor: pointer;
  
`

//------------------------------------------------------
export const Menu = styled.div`
display: flex ;
flex: 0.5;
align-items: center ;
width:100%;
margin-bottom: 10px ;
font-size: 14px ;

button{
  background-color: #5656f7;
  margin: 0 10px ;
  padding: 2px 10px ;
  color: white ;
  border-radius: 5px ;

}
`

// --------------------------------------------------------
export const bodyMeio = styled.div` 
display:flex;
flex-direction: column ;
width: 100% ;
flex:20 ;
background-color: black ;
justify-content: center ;
align-items: center ;

button{
  width: 100px ;
}
`
//---------------------------------------------
export const imagem = styled.div`
display: flex ;
width: 100% ;
align-items: flex-start;
justify-content: center ;


img{
  width: 50% ;
  min-width: 380px ;
}

`
// -------------------------------------------------------
export const ContainerSorteio = styled.div` 
display:${props=>props.playModalSorteio ? 'flex' : 'none'};
width:80%;
position: relative ;
top: 80px;
left: 40px ;
justify-content: center ;
align-items: center ;

`
export const divModal = styled.div`
display:flex;
flex-direction: column;
align-items: center;
padding:10px;
color: #00D594;
background-color:#f2f2f2;
border-radius:3px;
width:100% ;
font-size: 16px ;


`
//-----------------------------------------
export const boxModal = styled.div`
width:100%;
flex-direction:column;
margin:5px;
border-bottom:1px solid #5656f7;

`

export const PesqSorteio = styled.div`
display: flex ;
flex-direction:column;
align-items: center ;

`

export const lista = styled.div` 
flex-direction: column ;


`
//-----------------------------------------------------------

export const modal = styled.div`
display: flex ;
position: absolute ;
max-width: 390px ;
z-index:1 ;
`
// --------------------------------------------------------
export const Footer = styled.div`
display: flex ;
flex: 1 ;
justify-content: center ;
width:100%;
border-top:1px solid #5656f7;
font-size: 11px ;
color: white ;
background-color: black ;

div{
  display: flex ;
  margin-right: 10px ;
  align-items: center ;
  color: white ;
  padding: 10px 0 ;
}

`

// --------------------------------------------------------
export const Salvar = styled.div`
display:flex;
height:30px;
margin:3px;
justify-content:center;

button{
  background-color:${props=>props.detalhesModal ? '#ffbf00':'#00ff9d'};
  font-size: 15px;
  min-width: 150px;
  border:none;
  border-radius:3px;
  color:${props=>props.editModal ? 'black':'white'};

&:hover{
  background-color:#5656F7;
  color:white;
}
}
`

//-----------------------------
export const divButton = styled.div` 
display:flex;
flex-direction: column ;
margin: 10px 0 ;
width:100% ;

span{
  display: flex ;
  justify-content: end ;
  margin-right: 30px ;

  a{
    font-size:14px ;
  }

}
`