import React, { useEffect, useState, useRef } from 'react';

import * as S from './styles';
import logo_bg from '../midia/logo_bg.png'
import {HeaderLogo} from '../componentes/Header/HeaderLogo';

//----------------------------------------------------
export default function Site_autoboox() {  
    //camera frontal ou traseira
    const [front, setfront] = useState(false);
    const videosRef = useRef(null);
    const capturaRef = useRef(null);
    const [baixarURL, setbaixarURL] = useState(null)
//--------------------------------------------


//#################################################################################################
  return (
<S.body>
        <HeaderLogo/>

        <S.bodyMeio>
            <span style={{color:"white",'marginRight':"20px",'marginTop':"10px"}}>EM DESENVOLVIMENTO...</span>
            <S.imagem >
                <img src={logo_bg} />
            </S.imagem>
            
            
        </S.bodyMeio>
            
        <S.Footer>
                <span>Desenvolvido por - </span> 
                <span 
                style={{'color':"#00ff9d",'cursor':'pointer'}}
                onClick={()=> window.location.href = 'https://wa.me/qr/J6BX3LNBZACLH1'} 
                >
                    RBento 
                </span> 
        </S.Footer>
    </S.body>
  );
}