import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Home from '../../screen/index';
import Autoboox from '../../screen/Autoboox/index';
import webcam from '../../screen/Autoboox/webcam/App';

export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home}/>     
                <Route path="/autoboox" exact component={Autoboox}/>
                <Route path="/webcam" exact component={webcam}/>  
            </Switch>
        </BrowserRouter>
    )
}